import * as React from 'react';
import { trackPage } from '../lib/track/track';
import * as PageContentStyles from '../_scss/modules/_page-content.module.scss';
import { useStoryBlockCommon, useStoryblok } from '../lib/storyblok/storyblok';
import DynamicComponent from '../components/_common/dynamic-component/dynamic-component';
import { PageDefaultStoryblok } from '../_shared/interfaces/storyblok';
import Layout from '../components/_common/_layout/layout';
import SEO from '../components/_common/seo/seo';
import { IntlProvider } from 'react-intl';
import { ModalContextProvider } from '../context/modal/modal.context';
import { PageAccountStoryblokProvider } from '../context/storyblok/page-account-storyblok.context';
const PageDefault = ({ pageContext }: { pageContext: { story: { content: PageDefaultStoryblok; full_slug: string } } }) => {
    let story = pageContext.story;
    story = useStoryblok(story);
    const storyblokCommon = useStoryBlockCommon(story.full_slug);

    let header;
    if (story.content.header) {
        header = story.content.header.map((blok) => {
            return <DynamicComponent blok={blok} key={blok._uid} storyblokCommon={storyblokCommon} />;
        });
    }

    let components;
    if (story.content.body) {
        components = story.content.body.map((blok) => {
            return <DynamicComponent blok={blok} key={blok._uid} storyblokCommon={storyblokCommon} />;
        });
    }

    React.useEffect(() => {
        trackPage({
            country: process.env.GATSBY_COUNTRY,
            language: storyblokCommon.configuration.content.language,
            title: story.content.metadata.title,
            template: 'Default',
        });
    }, []);

    return (
        <IntlProvider locale={storyblokCommon.configuration.content.language}>
            <PageAccountStoryblokProvider blok={storyblokCommon.accountDetailPage.content}>
                <ModalContextProvider>
                    <Layout paddingDisabled={true} storyblokCommon={storyblokCommon}>
                        <SEO metadata={story.content.metadata} config={storyblokCommon.configuration.content} full_slug={story.full_slug} />
                        {header}
                        <div className={PageContentStyles.pageContentStandard}>{components}</div>
                    </Layout>
                </ModalContextProvider>
            </PageAccountStoryblokProvider>
        </IntlProvider>
    );
};

export default PageDefault;
